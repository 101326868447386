
import { VueAgile } from 'vue-agile'
export default {
  name: 'CarouselTwo',
  components: {
    VueAgile,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
}
