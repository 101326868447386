
export default {
  name: 'NavArrow',
  props: {
    side: {
      type: String,
      default: 'prev',
      validator(value) {
        return ['prev', 'next'].includes(value)
      },
    },
    iconSize: {
      type: String,
      default: 'w-8 h-8',
    },
    icon: {
      type: String,
      default: 'arrow-slider-white',
    },
    theme: {
      type: String,
      default: 'secondary',
    },
    position: {
      type: String,
      default: 'verticalCentre',
    },
  },
}
