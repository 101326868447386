import { render, staticRenderFns } from "./TwoColumnBlocksTurboItem.vue?vue&type=template&id=17fcd01c"
import script from "./TwoColumnBlocksTurboItem.vue?vue&type=script&lang=js"
export * from "./TwoColumnBlocksTurboItem.vue?vue&type=script&lang=js"
import style0 from "./TwoColumnBlocksTurboItem.vue?vue&type=style&index=0&id=17fcd01c&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@3.2.5_vue-temp_n4gdrmfirpmgaryqotnkfbokze/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CarouselTwo: require('/src/components/carousel/CarouselTwo.vue').default})
