
import { youtubeThumbUrl, getEmbeddedUrl } from '@/utils'

export default {
  name: 'TwoColumnBlocksTurboItem',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isModalOpen: false,
    }
  },
  methods: {
    youtubeThumbUrl,
    getEmbeddedUrl,
    setActiveSection(sectionId, slideId) {
      this.$emit('click', { sectionId, slideId })
    },
  },
}
